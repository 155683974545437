import React from "react"
import {graphql, Link, StaticQuery} from "gatsby"
import {getProcessedArticles, isValidContentfulImage, isValidSectionField} from "../../common/utils/CommonUtils"
import "../../styles.scss"
import "../../pages/learning-library.scss"

export const ProviderArticles = (props) => {
    return (
        <StaticQuery
            query={graphql`
            query AllRefinedCategories {
                allContentfulCategory {
                    nodes {
                        name
                        slug
                        id
                        topics {
                            id
                            name
                            slug
                            publishOnWebsite
                            educationOrder {
                                id
                                slug
                                authorName
                                contentLengthduration
                                publishOnWebsite
                                description
                                isPopular
                                authorImage {
                                    file {
                                        fileName
                                        url
                                    }
                                }
                                authorOrganization
                                titleImage {
                                    file {
                                        fileName
                                        url
                                    }
                                }
                                title {
                                    title
                                }
                            }
                        }
                    }
                }
              }  
      `}
            render={data => {

                const processedArticles = getProcessedArticles(
                    data.allContentfulCategory).reduce((map, article) => {
                    map[article.slug] = article;
                    return map
                }, {});
                const preparedArticles = props.articles
                    .filter(article => processedArticles[article.slug])
                    .map(article => {
                        return processedArticles[article.slug]
                    });

                return (
                    <div className="author-article-wrap">
                        <div className="author-articles-inner">
                            <h2 className="heading2">Articles
                                by {props.providerName} on Confidant Health</h2>
                            <div className="article-list">
                                {
                                    preparedArticles.map((article) => {
                                        return (
                                            <div className="single-article">
                                                {
                                                    isValidContentfulImage(article.titleImage) && (
                                                        <img
                                                            className="article-img"
                                                            src={article.titleImage.file.url}
                                                            alt="img"/>
                                                    )
                                                }
                                                <div className="top-content">
                                                    {
                                                        isValidSectionField(article, "title") &&
                                                        <h3 className="heading3">{article.title.title}</h3>
                                                    }
                                                    {
                                                        isValidSectionField(article, "description") &&
                                                        <p className="parah">{article.description ? article.description : ''}</p>
                                                    }
                                                </div>
                                                <div className="link-row">
                                                    <p className="article-time">
                                                        {
                                                            article.contentLengthduration ? article.contentLengthduration + " read time" : ""
                                                        }
                                                    </p>
                                                    {article.slug && (
                                                        <Link
                                                            to={`/${article.categorySlug}/${article.topicSlug}/${article.slug}`}
                                                            className="article-link">
                                                            Read article
                                                        </Link>

                                                    )}

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
};
