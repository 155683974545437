import React, {useState} from "react"
import Rating from "@material-ui/lab/Rating"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import "react-h5-audio-player/lib/styles.css"
import SEO from "../components/seo"
import {graphql,navigate} from "gatsby"
import CalendarIcon from "@material-ui/icons/DateRange"
import ArrowRightLong from "@material-ui/icons/ArrowRightAlt"
import TextField from "@material-ui/core/TextField"
import {ProviderArticles} from "../components/learning-library/provider-articles"
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import {isValidEmail} from "../common/utils/CommonUtils";
import "./provider-details.scss"
import BreadCrumbs from "../components/bread-crumbs";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started",
    },
    {
        title: "Online Therapy",
        link: "/online-therapy",
    },
    {
        title: "All Providers",
        link: "/online-therapy/providers",
    },

]

export default function (props) {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const data = props.data.contentfulProviderDetailPage;
    const {
        approach,
        articles,
        credentials,
        specialtyTag,
        educationDetails,
        providerName,
        topSection,
        reviews,
        providerImage,
        role,
        rating,
        totalReviews,
        branchLink,
    } = data;

    const bookAppointment = (event, email) => {
        event.preventDefault();

        if (isValidEmail(email)) {

            SegmentAnalytics.track(SegmentEvents.USER_REQUESTED_FOR_APPOINTMENT,
                {
                    email, providerName, role, branchLink,
                    appRequested: true,
                }).then(()=>{
                navigate("/get-started/")
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': providerName,
                'event': 'BOOK_APPOINTMENT',
                'email': email
            });
            setEmail("");
            setSuccess(true);
            setError(false);


        } else {
            setError(true);
            setSuccess(false)
        }
    };

    const getSortedArticles = () => {
        return articles
            .filter(article => article.publishOnWebsite === true)
            .sort(function (article1, article2) {
                return (article1.createdAt < article2.createdAt) ? -1
                    : ((article1.createdAt > article2.createdAt) ? 1 : 0)
            })
            .slice(0, 3)
    };

    return (
        <Layout location={props.location}>
            <SEO title="Provider Details"/>
            <BreadCrumbs currentPage={providerName}  breadCrumbsList={breadCrumbsList}/>
            <div className="provider-details-header">
                <div className="provider-details-inner">
                    {topSection && (
                        <div className="provider-details-pattern-content">
                            {topSection.title && (
                                <h2 className="provider-details-main-head">{topSection.title
                                    ? topSection.title : ""}</h2>
                            )}
                            {role && (
                                <p className="new-author-role">{role}</p>
                            )}
                            <div className="review-row">
                                <Rating readOnly={true} name="read-only"
                                        value={rating}/>
                                <h4 className="rating-text">{totalReviews} Reviews</h4>
                            </div>
                            {topSection.description && (
                                <p className="provider-details-para">{topSection.description.description}</p>
                            )}
                            <form method="post"
                                  onSubmit={(event) => bookAppointment(event,
                                      email)}>
                                <div className="get-app-input-wrap">
                                <div className="input-box">
                                    <TextField
                                        id="phone-input"
                                        className="number-field"
                                        placeholder="Enter your email address"
                                        value={email}
                                        margin="normal"
                                        onChange={event => {
                                            const { value } = event.target
                                            setEmail(value)
                                            setError(false)
                                            setSuccess(false)
                                        }}
                                    />
                                </div>
                                <Button
                                    className="blue-arrow-btn"
                                    color="primary"
                                    type="submit"
                                    //href={`/ServiceFlow1/`}
                                    >
                                        Book Appointment <CalendarIcon/>
                                    </Button>

                                    {error ? <p className="error-msg">Please
                                        enter a valid email address.</p> : null}
                                    {success ? <p
                                        className="success-msg">Request for
                                        appointment sent.</p> : null}

                                </div>
                            </form>
                        </div>
                    )
                    }

                    {providerImage && (
                        <div className="provider-details-image-side">
                            <img className="provider-details-hero-img"
                                 src={providerImage.file.url}
                                 alt="iPhone Visual"/>
                        </div>

                    )}
                </div>
            </div>

            {
                articles && (
                    <ProviderArticles providerName={providerName}
                                      articles={getSortedArticles()}/>
                )
            }

            <div className="provider-details-info-wrapper">
                <div className="provider-details-info-inner">
                    {
                        approach && approach.approach && (
                            <div className="text-info-box">
                                <h2 className="provider-info-header">Approach</h2>
                                <p className="provider-info-para"
                                   dangerouslySetInnerHTML={{__html: approach.approach}}>
                                </p>
                            </div>
                        )
                    }

                    {
                        reviews && reviews.length > 0 && (
                            <div className="recent-review-wrap">
                                <h2 className="heading2">Recent Reviews</h2>
                                <div className="review-list">
                                    {
                                        reviews.slice(0, 4).map(review => (
                                            <div className="single-review">
                                                <div className="info-row">
                                                    <p className="main-text">{review.name
                                                        ? review.name : ""}</p>
                                                    {review.rating && (
                                                        <Rating readOnly={true}
                                                                name="read-only"
                                                                value={review.rating
                                                                    ? review.rating
                                                                    : 0}/>
                                                    )}
                                                    <p className="time-text">{review.date
                                                        ? review.date : ""}</p>
                                                </div>
                                                <div className="content-row">
                                                    <p className="review-des">{review.reviewText
                                                        ? review.reviewText.reviewText
                                                        : ""}</p>
                                                </div>
                                                <div
                                                    className="review-author-box">
                                                    {review.reviewerImage.file.url
                                                    && (
                                                        <img
                                                            className="review-img"
                                                            src={review.reviewerImage.file.url}
                                                            alt="review"/>
                                                    )}
                                                    <div className="star-side">
                                                        <div
                                                            className="review-author-text">
                                                            {review.name && (
                                                                <p className="main-text">{review.name}</p>
                                                            )}
                                                            {review.date && (
                                                                <p className="time-text">{review.date}</p>
                                                            )}
                                                        </div>
                                                        <div
                                                            className="rating-box">
                                                            {review.rating && (
                                                                <Rating
                                                                    name="read-only"
                                                                    value={review.rating}/>
                                                            )}
                                                            {review.reviewText.reviewText
                                                            && (
                                                                <p className="review-des">{review.reviewText.reviewText}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    reviews && reviews.length > 4 && (
                                        <Button className="outline-btn"
                                                color="primary">See All Reviews
                                            ({reviews.length})</Button>
                                    )
                                }
                            </div>
                        )
                    }

                    {
                        credentials && credentials.length > 0 && (
                            <div className="educational-inner">
                                <h3 className="heading2">Credentials</h3>
                                <div className="credential-list">
                                    <ul className="creden-ul">
                                        {
                                            credentials.map((credential) => {
                                                return (
                                                    <li className="creden-li">{credential
                                                        ? credential : ""}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    }


                    {
                        specialtyTag && specialtyTag.length > 0 && (
                            <div className="educational-inner">
                                <h3 className="heading2">Specialities</h3>
                                <div className="credential-list">
                                    <ul className="creden-ul">
                                        {
                                            specialtyTag.map((specialty) => {
                                                return (

                                                    <li className="creden-li">{specialty
                                                        ? specialty : ""}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    }


                    {educationDetails && educationDetails.length > 0 && (
                        <div className="educational-inner">
                            <h3 className="heading2">Education</h3>
                            <div className="education-list">
                                {
                                    educationDetails.map(educationItem => (
                                        <div className="single-education">
                                            {educationItem.image?.file?.url && (
                                                <img className="edu-img"
                                                     src={educationItem.image.file.url}
                                                     alt="Logo"/>
                                            )}
                                            <div className="edu-text">
                                                {educationItem.name && (
                                                    <p className="main-text">{educationItem.name
                                                        ? educationItem.name
                                                        : ""}</p>
                                                )}
                                                {educationItem.description && (
                                                    <p className="sub-edu-text">{educationItem.description
                                                        ? educationItem.description
                                                        : ""}</p>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}
//TODO : add interviews,featured video interview, articles, reviews nodes in graphql query
export const query = graphql`
query getAuthorDetails($slug:String) 
{
  contentfulProviderDetailPage(slug: {eq: $slug}) {
      topSection {

        description {
          description
        }
        title
        imageOrVideo {
          file {
            contentType
            url
          }
        }
      }
      slug
      role
      rating
      credentials
      specialtyTag
      totalReviews
      approach {
        approach
      }
      educationDetails {
        name
        description
        image {
          file {
            contentType
            url
          }
        }
      }
      ... on ContentfulProviderDetailPage {
          articles {
          ... on ContentfulEducationalContent {
                title {
                  title
                }
                description
                slug
                publishOnWebsite
                isPopular
                contentLengthduration
                titleImage {
                  file {
                    contentType
                    url
                  }
                }
                createdAt
              }
          }
          branchLink
      }
      providerName
      providerImage {
        file {
          contentType
          url
        }
      }

  }
}`;
